import './App.css';
import { useState } from 'react';
import axios from 'axios';

function App() {

  const [count, setCount] = useState(0);
  const [persons, setPersons] = useState();

  function test() {
    axios.get(`https://jsonplaceholder.typicode.com/users`)
      .then(res => {
        setPersons(res.data);
      })
  }

  function showUsers() {
    console.log(persons);
  }

  return (
    <div>
        <p>You clicked {count} times</p>
        <button onClick={() => setCount(count + 1)}>
          Click me
        </button>
        <button onClick={() => test()}>
          Click me
        </button>

        <button onClick={() => showUsers()}>
          Show users
        </button>

        <hr></hr>


        
      </div>
  );
}

export default App;
